import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/layout';
import Seo from '../components/seo';

import { MainScreenFirst, CrumbsNav } from '../components/Pages/About';

// import { beautyDirectorsData } from '../db/beautyDirectorsData';
import { contactsData } from '../db/contactsData';
import { beautyDirectorsStatic } from '../db/beautyDirectorsStatic';
import BeautyDirectors from '../components/Pages/BeautyDirectors/BeautyDirectors';

const beautyDirectorsPage = ({data}) => {
  return (
    <Layout>
      <Seo title={'Beauty directors portfolio'} />
      <MainScreenFirst
        title={data.strapiBeautyDirectorsPage.main.title}
        socials={contactsData.socials}
        showreel={data.strapiBeautyDirectorsPage.main.showreel}
        video={data.strapiBeautyDirectorsPage.main.video_cover}
        poster={data.strapiBeautyDirectorsPage.main.poster}
      />
      <BeautyDirectors
        items={data.strapiBeautyDirectorsPage}
        tickers={beautyDirectorsStatic.tickers}
      />

      <CrumbsNav dataCrumbs={beautyDirectorsStatic.crumbsNav} />
    </Layout>
  );
};

export default beautyDirectorsPage;


export const query = graphql`
 query {
  strapiBeautyDirectorsPage {
      main {
        title
        showreel
        video_cover {
        url
      }
      poster {
        url
      }
      }
      Directors {
        link
        title
        video {
          url
        }
      }
    }
  }
`